body, html {
    margin: 0;
    height: 100%; /* Ensures full height for centering on larger screens */
    display: flex;
    justify-content: center; /* Horizontally center content on larger screens */
    align-items: center; /* Vertically center content on larger screens */
    background: #f0f0f0;
    overflow: hidden; /* Prevents scrolling when content fits within the viewport */
  }
  
  .scratch-card {
    position: relative;
    display: inline-block;
  }
  
  img {
    display: block; /* Removes bottom margin/gap */
    /*width: 360px;  Fixed width by default */
    /*height: 640px;  Fixed height by default */
    width: 100%; /* Full width of the viewport */
    height: 100vh; /* Fixed height by default */  
  }
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* Media queries for devices with a max width of 600px (usually phones) */
  @media (max-width: 600px) {
    body, html {
      display: block; /* Changes display from flex to block, disabling centering */
      height: auto; /* Adjust height to be automatic, not filling the viewport */
      overflow: auto; /* Allows scrolling if content is taller than the screen */
    }
  
    img {
      width: 100%; /* Full width of the viewport */
      height: auto; /* Height is automatic, maintaining the image's aspect ratio */
    }
  }
  